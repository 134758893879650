import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  // { path: '/', name: 'Home', component: Home },
  { path: '/', name: 'Store', props: true, component: () => import('../views/Store.vue') },
  { path: '/about', name: 'About', component: () => import('../views/About.vue') },
  { path: '/login', name: 'Login', props: true, component: () => import('../views/Login.vue') },
  { path: '/customers', name: 'Customers', props: true, meta: { requiresAuth: true }, component: () => import('../views/Customers.vue') },
  { path: '/checkout', name: 'Checkout', props: true, meta: { requiresAuth: true }, component: () => import('../views/CheckOut.vue') },
  { path: '/orders/details/:id', name: 'orderdetails', props: true, meta: { requiresAuth: true }, component: () => import('../views/Admin/OrderDetails.vue') },
]

const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const user = JSON.parse(localStorage.getItem("user"))
    if (!(user && user.status === 1)) {
      next({ path: '/' })
    } else {
      next()
    }
  } else {

    // try {
    //   const url = store.state.url + "getCurrentVersion"
    //   const req = await fetch(url)
    //   if (req.ok) {
    //     const res = await req.json()
    //     const localVersion = localStorage.getItem("localVersion") || 0
    //     if (localVersion != res.id) {
    //       localStorage.setItem("localVersion", res.id)
    //       window.location.reload()
    //     }
    //   }

    // } catch (error) {
    //   console.error(error)
    // }

    next() // make sure to always call next()!
  }
})

export default router