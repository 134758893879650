<template>
<div>
  <div id="main-slider" style="margin-top:-100px;">
    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="4000"
      controls
      
      background="#ababab"
      img-width="1024"
      img-height="300"
      style="text-shadow: 1px 1px 2px #333;"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"      
    >
      <!-- <b-carousel-slide :img-src="require('@/assets/slide1.jpg')" />
      <b-carousel-slide :img-src="require('@/assets/slide2.jpg')"/>
      <b-carousel-slide :img-src="require('@/assets/slide3.jpg')"/>
      <b-carousel-slide :img-src="require('@/assets/slide4.jpg')"/>
      <b-carousel-slide :img-src="require('@/assets/slide5.jpg')"/> -->
      <b-carousel-slide :img-src="require('@/assets/slide1.1.png')"/>
      <b-carousel-slide :img-src="require('@/assets/slide1.2.png')"/>
      <b-carousel-slide :img-src="require('@/assets/slide1.3.png')"/>
        
    </b-carousel>
  </div>

    <div class="container searchdiv mt-5" >
      <!-- <h4 class="text-center">BUSCAR PRODUCTO</h4> -->
      <b-input-group >
        <b-form-input placeholder="buscar por nombre ó sku" v-model="productSearch" class="text-center inputSearch" style="text-transform: uppercase;"></b-form-input>
        <b-input-group-append>
        <b-button @click.prevent="goSearch()" class="buttonsearch">Buscar</b-button>
        <!-- <b-button variant="info">Button</b-button> -->
      </b-input-group-append>
    </b-input-group>

    </div>
  <!-- categorias -->
  <div class="container mt-5 mb-5">
    <div class="row">
      <div class="title">
        CATEGORÍAS
      </div>
      <hr class="title-line">
    </div>


    <!-- categories large screen -->
    <div id="categoriesLarge" class="d-none d-sm-none d-md-block d-lg-block">
      <div class="row mt-3">
        <div v-for="item in 5" :key="item"  class="col-md col-sm-6 text-center" @click="gotoStore(item)">
          <img class="img-fluid zoom m-2" style="border-radius:2rem;" :src=" require('@/assets/categories/'+ item + '.png')" alt="modegt">
        </div>
      </div>
      <div class="row">
        <div v-for="item in 5" :key="item"  class="col-md col-sm-6 text-center" @click="gotoStore(item+5)">
          <img class="img-fluid zoom m-2 mt-3 mb-3" style="border-radius:2rem;" :src=" require('@/assets/categories/'+ (item+5) + '.png')" alt="modegt">
        </div>
      </div>
    </div>

    <!-- categories small screen -->
    <div id="categoriesSmall" class="d-block d-sm-block d-md-none d-lg-none">
      <div class="row mt-3">
        <div v-for="item in 10" :key="item"  class="col-6 pl-0 text-center" @click="gotoStore(item)">
          <img class="img-fluid zoom m-2" style="border-radius:2rem;" :src=" require('@/assets/categories/'+ item + '.png')" alt="modegt">
        </div>
      </div>
    </div>
  </div>


  <!-- products -->
  <div class="container mt-5 mb-5">
    <div class="row mt-2 mb-5">
      <div class="title">
        PRODUCTOS NUEVOS
      </div>
      <hr class="title-line">
    </div>

    <div class="row">
      <div class="col-md-3 col-sm-6" v-for="product in products" :key="product.id">
        <div @click="showProductDetail(product)">
          <product-vue  :product="product" class="zoom" />
        </div>
      </div>
    </div>
  </div>

  <!-- offers -->
  <div class="container-fluid mt-5 mb-5 bg-dark">
    <div class="container">
      <div class="row pt-5 pb-5" >
        <div class="col-md-3  col-sm-6" v-for="product in offerProducts" :key="product.id" @click="showProductDetail(product)">
          <product-vue :product="product" theme="product-alt" class="zoom" />
        </div>
      </div>
    </div>
  </div>


  <!--  -->
  <div class="container">
    <div class="row p-5">
      <div class="col-12">
        <h1 class="text-center ">Somos tu distribuidora <br>de <b>confianza.</b> </h1>
      </div>
    </div>
  </div>
  
  <b-modal id="modal-productDetail" hide-footer >
    <template #modal-title>
      Detalle del producto:
    </template>    
    <product-detail-vue @close="closeModal()" :productDetail="productData"/>    
  </b-modal>

  <!-- modal seleccion tienda -->
  <b-modal id="modal-chooseStore" hide-footer hide-header no-close-on-backdrop no-close-on-esc centered >
    <template #modal-title>
      Selecciona una tienda
    </template>    
    <choose-store @close="closeModal()" :productDetail="productData"/>  
  </b-modal>
</div>
</template>

<script>
import ProductVue from "../components/Product.vue";
import ProductDetailVue from "../components/ProductDetail.vue";
import chooseStore from "../components/ChooseStore";

export default {
  components: { ProductVue, ProductDetailVue, chooseStore },
  data() {
    return {
      slide: 0,
      sliding: null,
      categories: [],
      productData: {},
      storeId: 0,
      productSearch: null,

      products: [],

      offerProducts: [],
    };
  },
  mounted() {
    this.$store.watch(
      (state) => state.refreshListProductsKey,
      (value) => {
        this.getProducts();
        this.productsOffer();
      }
    );
    if (!localStorage.getItem("storeId")) {
      this.$bvModal.show("modal-chooseStore");
    } else {
      this.storeId = localStorage.getItem("storeId");
      this.getProducts();
      this.productsOffer();
    }
  },
  methods: {
    goSearch() {
      localStorage.setItem("search", this.productSearch);
      this.$router.push("store/0");
    },
    gotoStore(category) {
      this.$router.push("store/" + category);
    },
    async getProducts() {
      try {
        this.storeId = localStorage.getItem("storeId");
        this.products = await this.$store.dispatch(
          "getAll",
          "newProductsByStore/" + this.storeId
        );
      } catch (error) {
        alert(error);
      }
    },
    async productsOffer() {
      try {
        this.storeId = localStorage.getItem("storeId");
        this.offerProducts = await this.$store.dispatch(
          "getAll",
          "productsOffer/" + this.storeId
        );
        if(this.offerProducts){
          this.offerProducts = this.offerProducts.filter(x => x.variations.length>0);
        }
      } catch (error) {
        alert(error);
      }
    },
    showProductDetail(item) {
      item["quantity"] = 1;
      this.productData = item;
      this.$bvModal.show("modal-productDetail");
    },
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
    showModal() {
      this.$bvModal.show("modal-chooseStore");
    },
    closeModal() {
      this.$bvModal.hide("modal-chooseStore");
      this.$bvModal.hide("modal-productDetail");
    },
  },
};
</script>
<style>
.searchdiv {
  /* background-color:#fd306e !important; */
  /* background-color: black !important; */
  /* padding: 30px;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.25);
  border-radius: 20px; */
}
.buttonsearch {
  background-color: #fd306e !important;
  color: white;
}
.inputSearch {
  border-color: #fd306e !important;
}

.carousel-item img {
  /* height:100vh!important ; */
}

@media only screen and (max-width: 600px) {
  .carousel-item img {
    height: 45vh !important ;
  }
}
</style>