<template>
  <div id="app">
    <navbar/>
    <router-view/>

    <footer-vue/>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import FooterVue from "./components/Footer.vue";
export default {
  components: { Navbar, FooterVue },
};
</script>

<style lang="scss">
@import "app.scss";
@import "~sweetalert2/src/variables";

//$swal2-background: #990000;

@import "~sweetalert2/src/sweetalert2";
.pointer {
  cursor: pointer;
}
</style>
