<template>
  <div :class="className">
    <!-- <section :style="`background:url(${product.picture}); background-size:100% 100%;  height:10rem; margin:1rem; background-repeat: no-repeat;`"/> -->
    <img @click="showProductDetail(product)" class="productimg" :src="product.url != null ? product.url : 'https://www.clipartmax.com/png/middle/250-2502047_question-mark-question-mark.png'" alt="" />
    <br />
    <!-- <span style="margin:auto; display:table;font-size:0.8rem" class="mb-1">{{ "Código: " + padToFour(product.id) }}</span> -->
    <h6 @click="showProductDetail(product)" style="height:2.5rem; font-weight:bold" class="text-center mb-5">{{ product.nombre }}</h6>
    <!-- <h6 v-if="product.offerPrice" class="text-center text-muted mt-3">
      <del> Antes: Q{{ Number(product.precio_venta).toFixed(2) }}</del>
    </h6> -->
    <h4 @click="showProductDetail(product)" class="text-center"> C$ {{ Number(product.precio_venta).toFixed(2) }}</h4>
    <!-- <a href="https://wa.me/50555047184" target="_blank" ><img class="productimg" src="/img/icons/whatsapp2.png" style="width:40px; height:40px" alt="" /></a> -->
    <a :href="'https://api.whatsapp.com/send?phone=+50558270115&text='+'Hola, Quiero información de este producto: '+product.nombre+ ' ' + product.url" target="_blank" ><img class="productimg" src="/img/icons/whatsapp2.png" style="width:40px; height:40px" alt="" /></a>
  </div>
  
</template>

<script>
export default {
  props: ["product", "theme"],
  data: () => ({
    className: "product",
    user: null,
  }),
  mounted() {
    this.user = JSON.parse(localStorage.getItem("user")) || false; //this.$store.state.user!=null ? true : false;
    if (this.theme) {
      this.className = this.theme;
    }
  },
  methods: {
    showProductDetail(item) {
      item["quantity"] = 1;
      localStorage.setItem("productdata", JSON.stringify(item));

      this.$bvModal.show("modal-productDetail");
    },    
    padToFour(number) {
      if (number <= 9999) {
        number = ("00000" + number).slice(-6);
      }
      return number;
    },
  },
};
</script>

<style lang="scss" scoped>
$primary: #2c4ed6;

.product {
  background: transparent;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  margin: 1rem;
  padding: 1rem;
}

h4 {
  color: $primary;
}

.product-alt {
  background: transparent;
  border: 1px solid $primary;
  border-radius: 20px;
  margin: 1rem;
  padding: 1rem;
  color: #fff;
}
.productimg {
  height: 10rem;
  width: 80%;
  display: block;
  margin: auto;
}

@media screen and (max-width: 600px) {
  .productimg {
    height: 10rem;
    width: 50%;
    display: block;
    margin: auto;
  }
}
</style>
