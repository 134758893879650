<template>
  <div class="container-fluid text-light p-5 text-center" style="background:#111; border-top:1rem solid #2c4ed6">
    <div class="row">
      <!-- <div class="offset-md-1 col-md p-4">
              <strong class="mb-3"> DIRECCIÓN TIENDAS</strong>              
                <p style="font-size:0.80rem; margin-top:1rem">
                   * Local 30 Centro Comercial Bella Vista, <br>Jocotenango Sacatepéquez.
                </p>
                <p style="font-size:0.80rem; margin-top:1rem">
                   * Local 24 Centro Comercial Molino las Flores, <br>Segundo Nivel, zona 2, <br>Calzada Roosvelt, Guatemala.
                </p>
          </div> -->
      <div class="col-md p-4">
        <strong class="mb-3"> HORARIO DE ATENCIÓN</strong>
        <p style="font-size:0.80rem; margin-top:1rem">
          Lunes a Viernes <br />
          de 9:00AM - 5:30PM
        </p>
        <p style="font-size:0.80rem; margin-top:1rem">
          Sabados de <br />
          8:00AM - 01:00PM
        </p>
      </div>
      <div class="col-md p-4">
        <strong class="mb-3"> INFORMACIÓN DE CONTACTO</strong>

        <!-- <p style="font-size:0.80rem; margin-top:1rem">
                    It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of 
                </p> -->
        <ul class="mt-5">
          <div class="row">
            <div class="col-12">
              <span><img class="productimg" src="/img/icons/tigo.png" width="40" alt="ModeGt" />&nbsp;&nbsp;7836-9035</span> 
            </div>
            <div  class="col-12">
              <span><img class="productimg" src="/img/icons/claro.png" width="50" alt="ModeGt" />&nbsp;5827-0115</span>
            </div>

          </div>

          <!-- <a href="https://www.facebook.com/modeegt" target="_blank"><li><i class="fa fa-facebook"></i></li></a>
                    <a href="https://www.facebook.com/modeegt" target="_blank"><li><i class="fa fa-facebook"></i></li></a> -->
          <!-- <a href="https://www.tiktok.com/@modeguatemala?lang=es" target="_blank"><li><i class="fab fa-tiktok"></i></li></a>
                    <a href="https://www.instagram.com/modeguatemala/" target="_blank"><li><i class="fa fa-instagram"></i></li></a> -->
        </ul>
      </div>
      <div class="col-md-1"></div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
ul {
  display: flex;
  justify-content: center;
  padding-left: 0px;
}
ul li {
  position: relative;
  display: block;
  color: rgb(233, 232, 232);
  font-size: 20px;
  height: 40px;
  width: 40px;
  background: #292525;
  line-height: 40px;
  border-radius: 50%;
  margin: 0 15px;
  cursor: pointer;
  transition: 0.5s;
}
a ul li:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  height: inherit;
  width: inherit;
  /* background: #d35400; */
  border-radius: 50%;
  transform: scale(0.9);
  z-index: -1;
  transition: 0.5s;
}
ul li:nth-child(1):before {
  background: #4267b2;
}
ul li:nth-child(2):before {
  background: #1da1f2;
}
ul li:nth-child(3):before {
  background: #2c4ed6;
}
ul li:nth-child(4):before {
  background: #2867b2;
}
ul li:nth-child(5):before {
  background: #ff0000;
}
ul li:hover:before {
  filter: blur(3px);
  transform: scale(1.2);
  /* box-shadow: 0 0 15px #d35400; */
}
ul li:nth-child(1):hover:before {
  box-shadow: 0 0 15px #4267b2;
}
ul li:nth-child(2):hover:before {
  box-shadow: 0 0 15px #1da1f2;
}
ul li:nth-child(3):hover:before {
  box-shadow: 0 0 15px #2c4ed6;
}
ul li:nth-child(4):hover:before {
  box-shadow: 0 0 15px #2867b2;
}
ul li:nth-child(5):hover:before {
  box-shadow: 0 0 15px #ff0000;
}
ul li:nth-child(1):hover {
  color: #2c4ed6;
  box-shadow: 0 0 15px #e23670;
  text-shadow: 0 0 15px #e23670;
}
ul li:nth-child(2):hover {
  color: #26a4f2;
  box-shadow: 0 0 15px #1da1f2;
  text-shadow: 0 0 15px #1da1f2;
}
ul li:nth-child(3):hover {
  color: #e23670;
  box-shadow: 0 0 15px #e1306c;
  text-shadow: 0 0 15px #e1306c;
}
ul li:nth-child(4):hover {
  color: #2a6cbb;
  box-shadow: 0 0 15px #2867b2;
  text-shadow: 0 0 15px #2867b2;
}
ul li:nth-child(5):hover {
  color: #ff1a1a;
  box-shadow: 0 0 15px #ff0000;
  text-shadow: 0 0 15px #ff0000;
}
/* ul li:hover{
  color: #ffa502;
  box-shadow: 0 0 15px #d35400;
  text-shadow: 0 0 15px #d35400;
} */
</style>
