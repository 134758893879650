import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // url: "http://localhost:3030/api/",
    // url: 'http://localhost:3850/api/',
    // url: "https://modegt.herokuapp.com/api/", // produccion
    // url: "https://modegt-staging.herokuapp.com/api/", // staging
    url: "https://siscoper.mecasite.com/api/",
    user: null,
    refreshCartKey: 0,
    refreshCartInCheckOutKey: 0,
    refreshListProductsKey: 0,
    refreshLogoutButtonKey: 0,
  },
  mutations: {
    setUser(store, payload) {
      store.user = payload;
    },
    refreshCart(store, payload) {
      store.refreshCartKey++;
    },
    refreshCartInCheckOut(store, payload) {
      store.refreshCartInCheckOutKey++;
    },
    refreshListProducts(store, payload) {
      store.refreshListProductsKey++;
    },
    refreshLogoutButton(store, payload) {
      store.refreshLogoutButtonKey++;
    },
  },
  actions: {
    async login(context, { data }) {
      try {
        const options = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        };
        console.log(options);
        let req = await fetch(context.state.url + "usuarios/autenticate", options);
        let res = await req.json();
        //console.log(res)
        context.state.token = res.token;
        localStorage.setItem("tk", res.token);
        localStorage.setItem("user", JSON.stringify(res.data));
        if (res.error) {
          //this.$swal({title:"Aviso", text:"Datos almacenados correctamente", icon:"success"})
          //alert(res.error);
          return false;
        } else {
          //console.log('login')
          return res;
        }
      } catch (error) {
        console.error("error", error);
        return 0;
      }
    },

    async getAll(context, table) {
      try {
        let token = localStorage.getItem("tk");
        const options = {
          method: "GET",
          headers: {
            "access-token": token,
            "Content-Type": "application/json",
          },
        };
        let req = await fetch(context.state.url + table, options);
        let res = await req.json();
        if (res.mensaje == "Token inválida") {
          router.push("/");
        }
        if (res.error) {
          console.error(res.error);
          alert(res.mensaje);
        } else {
          return res;
        }

        // const url = context.state.url + table
        // const req = await fetch(url)
        // if (req.ok) {
        //   return await req.json()
        // } else {
        //   throw await req.text()
        // }
      } catch (error) {
        console.error(error);
      }
    },

    async findById(context, { table, id }) {
      try {
        const url = context.state.url + table + "/" + id;
        const req = await fetch(url);
        if (req.ok) {
          return await req.json();
        } else {
          throw await req.text();
        }
      } catch (error) {
        console.error(error);
      }
    },

    async saveNew(context, { table, data }) {
      try {
        const url = context.state.url + table;
        let opts = {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        };
        const req = await fetch(url, opts);
        if (req.ok) {
          return await req.json();
        } else {
          throw await req.text();
        }
      } catch (error) {
        console.error(error);
        return error;
      }
    },

    async update(context, { table, data, id }) {
      try {
        const url = context.state.url + table + "/" + id;
        let opts = {
          method: "put",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        };
        const req = await fetch(url, opts);
        if (req.ok) {
          return await req.json();
        } else {
          throw await req.text();
        }
      } catch (error) {
        console.error(error);
      }
    },

    async deleteItem(context, { table, id }) {
      try {
        const url = context.state.url + table + "/" + id;
        let opts = {
          method: "delete",
          headers: { "Content-Type": "application/json" },
        };
        const req = await fetch(url, opts);
        if (req.ok) {
          return await req.json();
        } else {
          throw await req.text();
        }
      } catch (error) {
        console.error(error);
      }
    },
    parseDate(context, date) {
      let d = date.getDate();
      if (d < 10) {
        d = "0" + d;
      }
      let m = date.getMonth() + 1;
      if (m < 10) {
        m = "0" + m;
      }
      let y = date.getFullYear();
      return `${y}-${m}-${d}`;
    },
  },
  modules: {},
});
