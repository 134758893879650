<template>
    <div class="container">
        <div v-if="!checkout">
            <div class="row">
                <div class="col-md-12">
                    <img :src="product.url != null ? product.url : 'https://www.clipartmax.com/png/middle/250-2502047_question-mark-question-mark.png'" style="height:40vh; display:block; margin:auto" alt="">
                    <!-- <span style="margin:auto; display:table;font-size:0.8rem" class="mt-2 mb-1">{{ 'SKU ' + product.code }}</span> -->
                    <h5 style="height:2.5rem" class="text-center mt-2">{{product.nombre}}</h5>
                    <p class="mt-1 mb-2 text-center"><i>{{ product.descripcion }}</i></p>
                </div>
                <!-- precio -->
                <div class="col-md-12 mt-1">
                    <!-- <h6 v-if="product.offerPrice" class="text-center text-muted"><del> Antes: Q{{Number(product.price).toFixed(2)}}</del></h6> -->
                    <h4 class="text-center"> C$ {{Number(product.precio_venta).toFixed(2)}}</h4>
                </div>
            </div>
            <!-- lista de precios -->
            <!-- <template v-if="user">
              <h5 class="text-center mt-1">Lista de Precios:</h5>
              <div class="row text-center">
                  <table class="table table-hover">
                      <thead style="background-color:#111 !important; color:white">
                          <tr>
                              <th width="70%" class="text-center">Descripción</th>
                              <th width="30%" class="text-center">Precio</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr v-for="item of listPrices" :key="item.id">
                              <td>Desde {{item.lowestQuantity == 1 ? item.lowestQuantity + ' unidad' : item.lowestQuantity + ' unidades' }} </td>
                              <td>Q {{item.price}}</td>
                          </tr>
                      </tbody>
                  </table>
              </div>
            </template> -->
            <!-- select variaciones -->
            <!-- <template>
              <div class="mt-3">
                <b-form-select v-model="selected" class="mb-3" @change="changeVariation()">
                    <b-form-select-option :value="null" disabled>-- Por favor selecciona una variación --</b-form-select-option>
                    <b-form-select-option class="text-center" v-for="item of product.variations" 
                    :value="item" :key="item.id">{{ product.name + ' - ' + item.name }} </b-form-select-option>
                </b-form-select>
              </div>
            </template>             -->
            <!-- <div class="row text-center">
                <div class="col-md-12 mt-1">
                    <strong>{{product.stock }} disponibles</strong>
                </div>
            </div> -->
            <div class="row mt-1">
                <div class="col-md-4 offset-md-4 mt-4 text-center">
                    <!-- <div class="input-group">
                        <div class="input-group-prepend">
                            <button @click="changeQuantity(1)" min="1" style="min-width: 2.5rem" class="btn btn-decrement btn-outline-secondary btn-minus" type="button">
                                <strong>−</strong>
                            </button>
                        </div>
                        <span type="text" style="text-align: center" class="form-control">{{ quantity }} </span>
                        <div class="input-group-append" >
                            <button @click="changeQuantity(2)" style="min-width: 2.5rem" class="btn btn-increment btn-outline-secondary btn-plus" type="button">
                                <strong>+</strong>
                            </button>
                        </div>
                    </div> -->
                        <!-- :disabled="!selected && !user"  style="background:#fd306e;color:white; border-radius:5px; border: solid 1px #fff;" > -->
                    <!-- <button v-if="!checkout" @click="addToCart()" class="form-control btn mt-2" 
                        :disabled="!user || !selected"  style="background:#fd306e;color:white; border-radius:5px; border: solid 1px #fff;" >
                        <b-icon-cart-fill style="font-size:1.75rem;"> </b-icon-cart-fill> Agregar
                    </button> -->
                    <!-- <span v-if="!user" class="text-dark" style="font-size:10px">para agregar producto debes iniciar sesión</span> -->
                </div>
                <div class="col mt-4">
                </div>
            </div>
        </div>
        <!-- in checkOut -->
        <!-- <div v-if="checkout">
            <div class="row">
                <table class="table checkout-table">              
                  <tr>
                      <td width="20%" scope="row"><img :src="product.picture != null ? product.picture : 'https://www.clipartmax.com/png/middle/250-2502047_question-mark-question-mark.png'" style="height:10rem; display:block; margin:auto; height:50px" alt=""></td>
                      <td width="40%" scope="row" class="text-start">
                          <span>{{product.code}} <br/> </span><b>{{product.name}}</b>  <br>
                          <span v-if="product.variation">{{product.variation.name}}</span>
                      </td>                            
                      <td  scope="row">
                          <div class="input-group" style="width:50px">
                              <span type="text" style="text-align: center" class="form-control">{{ product.quantity }} </span>
                          </div>
                      </td>
                      <td class="text-center">
                          <span class="text-center"><span v-if="product.offerPrice" style="font-size:1rem">Ahora</span>Q{{Number(product.price).toFixed(2)}}</span>
                      </td>                      
                      <td class="text-center">
                          <b class="text-center">Q{{Number(product.price * product.quantity).toFixed(2)}}</b>
                      </td>
                  </tr>                    
                </table>
            </div>
        </div> -->

        <!-- <b-modal centered  id="modal-question" hide-header hide-footer class="m-3">
            <div class="row">
                <div class="col-12 text-center">
                    <img src="https://images-na.ssl-images-amazon.com/images/I/418wK%2B6vaQL.png" width="120" alt="modegt">
                    <h3 class="text-dark">Producto Agregado a su carrito</h3>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <button @click="responseDialogue(1)" class="form-control btn mt-2" style="background:#fd306e;color:white; border-radius:5px; border: solid 1px #fff;" >
                        Continuar comprando
                    </button>
                </div>
                <div class="col-md-6">
                    <button @click="responseDialogue(2)" class="form-control btn mt-2" style="background:#111;color:white; border-radius:5px; border: solid 1px #fff;" >
                        Finalizar Compra
                    </button>
                </div>
            </div>
        </b-modal> -->
    </div>
</template>

<script>
import Vue from 'vue'

export default {
  props: ["checkout"],
  data: () => ({
    quantity: 1,
    product: {},
    listPrices: [],
    selected: null,
    options: [
      { value: null, text: "- Selecciona color -" },
      { value: "id", text: "Rojo - 5 unidades disponibles" },
      { value: "id", text: "Negro - 10 unidades disponibles" },
    ],
    stock: 0,
    user: null,
    storeId:0,
    productDetail:{}
  }),
  async mounted() {
    this.productDetail = JSON.parse(localStorage.getItem('productdata'))
    // this.storeId = JSON.parse(localStorage.getItem('storeId'))
    // this.user = JSON.parse(localStorage.getItem("user")) || false;
    this.product = this.productDetail;
    // await this.getPricesList();
  },
  methods: {
    changeVariation() {
      this.quantity = 1;
      this.stock = this.selected.stock;
    },
    async changeQuantity(type) {
      if (type == 1) {
        if (this.quantity > 1) {
          this.quantity--;
        }
      } else {
        if (this.quantity < this.stock) {
          this.quantity++;
        }
      }
      this.product.price = await this.getProductPrice(
        this.product.id,
        this.quantity
      );
      this.$store.commit("refreshCartInCheckOut");
    },
    async getPricesList() {
      try {
        const query = await this.$store.dispatch(
          "getAll",
          "productPrices/" + this.product.id + "/2" //el dos es un parámetro que muestra el precio mínimo
        );
        if (query) {
          this.listPrices = query;
        }
      } catch (error) {}
    },
    async getProductPrice(productId, quantity) {
      try {
        const query = await this.$store.dispatch(
          "getAll",
          "productPrice/" + productId + "/" + quantity
        );
        if (query.price) {
          return query.price;
        }
      } catch (error) {
        return 0;
      }
    },
    responseDialogue(response) {
      this.$bvModal.hide("modal-question");
      this.$emit("close");
      if (response == 2) {
        this.$router.replace("/Checkout");
      }
    },
    async addToCart() {
      try {
        let productListCart = [];
        productListCart =
          JSON.parse(localStorage.getItem("productDetail-"+ this.storeId)) || [];

        if (!this.checkout) this.product.quantity = this.quantity;
        // agregar producto al carrito

        let product = this.product;
        product.variation = this.selected;
        product.quantity = this.quantity;

        productListCart.push(product);
        localStorage.setItem("productDetail-"+this.storeId, JSON.stringify(productListCart));

        //this.$bvModal.show("modal-question");
        this.$store.commit("refreshCart")

        Vue.$toast.success('Producto agregado al carrito.',  { position: 'top-right'})
        this.$emit("close");

      } catch (error) {
        console.log("error catch", error);
      }
    },
  },
};
</script>

<style>
.table__wrapper {
  width: 100%;
}

.table__wrapper .table {
  width: 100%;
  max-width: 100%;
}

/* ------- Presentational Formatting --------- */
h1 {
  text-align: center;
}

.center {
  margin: 0 auto;
  width: 80%;
}

.table {
  border-collapse: collapse;
}

.table td,
.table th {
  border-top: none;
  padding: 0.5em;
  text-align: left;
}
.table tr {
  border-top: none;
}

@media screen and (max-width: 991px) {
  .table {
    margin: 0 auto;
    width: 100%;
    border-spacing: 0;
  }
  .table thead {
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0;
    border: 0;
    height: 1px;
    width: 1px;
    overflow: hidden;
  }
  .table tbody,
  .table tr,
  .table th,
  .table td {
    display: block;
    padding: 0;
    text-align: left;
    white-space: normal;
  }
  .table tr td,
  .table tr th {
    padding: 1em 1em;
    vertical-align: middle;
    overflow: hidden;
    position: relative;
    vertical-align: top;
    border-top: none;
    width: 100%;
    white-space: normal;
  }
  .table th[scope="row"] {
    width: 100%;
    text-align: center;
    display: block;
    background-color: #b3bfc6;
    margin: 0 auto;
    padding: 0.5em 0;
  }
  .table td[data-header]:before {
    content: attr(data-header);
    display: block;
    float: left;
    width: 50%;
    color: #ffffff;
    font-weight: bold;
    text-align: left;
  }
  .table td[data-header] > * {
    display: block;
    width: 50%;
    float: right;
    clear: right;
    padding-left: 1em;
    margin-top: 0;
  }
  .table td[data-header]:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    padding-bottom: 200%;
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .center {
    width: 100%;
  }
}

table .thead-light th {
  background-color: #fd306e !important;
  color: white !important;
}
</style>